import React, { useEffect, useState } from "react";

import { usuarios as titles } from "consts/tableTitles";

import { Buttons, CircularIndeterminate, Filters, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    snackbarData,
    modalData
} from "recoil/GlobalState";

import { EmailEditIcon, LupaIcon, ReloadIcon, CONActivar } from "assets/icons";

import { ROUTES } from "consts/routes";

import { ConnectionModal, UpdateModal, UserFilters } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";
import { Link } from "react-router-dom";
import ServerPaginationGrid from "components/UI/Grid/PaginatedGrid";
import { ACTIONS } from "consts/actions";

export default function Usuarios() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [usuariosLocalState, setUsuariosLocalState] = useState([]);

    const [pages, setPages] = useState([]);
    const [rowCount, setRowCount] = useState(100);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [filters, setFilters] = useState({
        usuarioNroDeDocumentoX: null,
        usuarioApellidos: null,
        usuarioMail: null,
        paisId: null,
        aplicacionId: null
    });

    const handleUpdateTable = (params) => {
        setFilters(params);
        formatUsuarios({ submit: true, ...params });
    };

    const formatUsuarios = async ({
        usuarioNroDeDocumentoX = null,
        usuarioApellidos = null,
        usuarioMail = null,
        paisId = null,
        aplicacionId = null,
        actualizar = false,
        submit = false
    }) => {
        if (!actualizar && !submit && pages[page] && page !== numberOfPages) {
            return setUsuariosLocalState(pages[page]);
        }
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "EndUserListGet",
            params: {
                usuarioNroDeDocumentoX,
                usuarioApellidos,
                usuarioMail,
                paisId,
                aplicacionId,
                page: page + 1,
                pageSize
            },
        };
        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const usuariosPartial = result?.result?.reduce((acc, item) => {
            const {
                paisCod,
                paisId,
                paisNombre,
                tipoDeDocumentoCod,
                usuarioApellidos,
                usuarioFechaAlta,
                usuarioIDXId,
                usuarioId,
                usuarioMail,
                usuarioNombres,
                usuarioNroDeDocumento,
                usuarioSexo,
                usuarioStatusRegistro,
                usuarioTelefonoCelular,
                usuarioVersion
            } = item;

            const usuarios = {
                id: usuarioId,
                paisCod: paisCod,
                paisId: paisId,
                paisNombre: paisNombre,
                tipoDeDocumentoCod: tipoDeDocumentoCod,
                usuarioApellidos: usuarioApellidos,
                usuarioFechaAlta: usuarioFechaAlta,
                usuarioIDXId: usuarioIDXId,
                usuarioMail: usuarioMail,
                usuarioNombres: usuarioNombres,
                usuarioNroDeDocumento: usuarioNroDeDocumento,
                usuarioSexo: usuarioSexo,
                usuarioVersion: usuarioVersion,
                usuarioStatusRegistro: usuarioStatusRegistro,
                usuarioTelefonoCelular: usuarioTelefonoCelular,
                [titles[0]]: usuarioNombres,
                [titles[1]]: usuarioApellidos,
                [titles[2]]: paisNombre,
                [titles[3]]: parseInt(usuarioNroDeDocumento).toLocaleString('es'),
                [titles[4]]: usuarioSexo,
                [titles[5]]: usuarioMail,
                [titles[6]]: usuarioTelefonoCelular,
                [titles[7]]: usuarioFechaAlta
                    ? usuarioFechaAlta.slice(0, 10)
                    : "",
                [titles[8]]: usuarioStatusRegistro
            };

            return [...acc, usuarios];
        }, []);

        const newPages = [...pages];
        newPages[page] = usuariosPartial;
        setPages(newPages);
        setUsuariosLocalState(usuariosPartial);
        setRow({ status: false, row: {} });
        setRowCount(result?.total);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (Object.values(credentials).length === 0) return;
        formatUsuarios(filters);
    }, [credentials]);

    useEffect(() => {
        handleUpdateTable({ ...filters, submit: false });
    }, [page]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal>
                {action === ACTIONS.DETAIL && (
                    <ConnectionModal usuarioId={row.row.id} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal
                        handleUpdateTable={() => formatUsuarios({ ...filters, actualizar: true })}
                    />
                )}
            </TransitionModal>
            <Buttons filtersApplied>
                <Filters>
                    <UserFilters handleSubmit={handleUpdateTable} setFilters={setFilters} filters={filters} />
                </Filters>
                <span>
                    {row.status ? (
                        <>
                            <Link
                                to={`/usuarios_detalle?user_id=${row.row.id}`}
                            >
                                <HeaderButton
                                    text={"Ver detalle"}
                                    icon={LupaIcon}
                                />
                            </Link>
                            <HeaderButton
                                text={"Ver conexiones"}
                                x
                                icon={CONActivar}
                                data={{
                                    action: ACTIONS.DETAIL,
                                    title: "Conexiones",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar mail"}
                                x
                                icon={EmailEditIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Editar mail",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Actualizar página"}
                                icon={ReloadIcon}
                                updateList={() => formatUsuarios({ ...filters, actualizar: true })}
                            />
                        </>
                    )}
                </span>
            </Buttons>

            {isLoading ? <CircularIndeterminate /> :
                <ServerPaginationGrid
                    rows={usuariosLocalState}
                    titles={titles}
                    rowCount={rowCount}
                    isLoading={isLoading}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    numberOfPages={numberOfPages}
                    setNumberOfPages={setNumberOfPages}
                />
            }
        </section>
    );
}
