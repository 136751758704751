import React from "react";

import Conexion from "pages/conexiones/Conexion";
import { Button } from "components";

import styles from "styles/pages/usuariosBO/EndUserModal.module.scss";

export default function ConnectionModal({
    usuarioId,
    closeModal = () => { }
}) {
    return (
        <div style={{ width: '100%' }}>
            <Conexion usuarioId={usuarioId} />

            <div className={styles.closeButtonContainer}>
                <Button
                    onClick={closeModal}
                    texto={"Cerrar"}
                />
            </div>
        </div>
    );
}
