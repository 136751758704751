import React, { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useImages } from "hooks/useImages";

import rotar from "assets/icons/rotate.png";

import * as Yup from "yup";

import { Form, Formik } from "formik";

import ModalCropImage from "./ModalCropImage";
import { OnBoardingManualForm } from "./OnBoardingManualForm";

import { countryList } from "consts/formInfo";

import { Button, CircularIndeterminate, TransitionModal } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { ACTIONS } from "consts/actions";

import styles from "styles/pages/usuariosNoUsuarios/PreonboardingManual.module.scss";
import { APLICACION_COD } from "consts/env";

export default function OnBoardingDetail() {
    const [searchParams] = useSearchParams();

    const [images, setImages] = useState({
        selfie: null,
        dniFrente: null,
        dniDorso: null,
        recorte: null,
    });
    const [recorte, setRecorte] = useState();
    const [userNoUser, setUserNoUser] = useState({});
    const [userInfo, setUserInfo] = useState(null);
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [documentType, setDocumentType] = useState([]);
    const [documentRegex, setDocumentRegex] = useState(null);
    const [paises, setPaises] = useState([]);
    const [paisSelected, setPaisSelected] = useState(null);
    const [aplicaciones, setAplicaciones] = useState([]);

    const selfieRef = useRef(null);
    const frenteRef = useRef(null);
    const dorsoRef = useRef(null);
    const recorteRef = useRef(null);

    const credentials = useRecoilValue(userCredentials);
    const [modalDetails, setModalDetails] = useRecoilState(modalData);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const { handleImg, handleRotate, downloadIMG } = useImages(
        setImages,
        images
    );

    const getPaises = async () => {
        const body = {
            service: "MIAPaisGet",
            params: { paisConBandera: 'N' },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setPaises(result);
    };

    const getDocumentType = async (paisId, documentTypeId) => {
        const body = {
            service: "TipoDeDocumentoGet",
            params: {
                paisId: paisId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        if (documentTypeId)
            setDocumentRegex(
                result.filter(
                    (doc) => doc.tipoDeDocumentoId === documentTypeId
                )[0].tipoDeDocumentoRegExp
            );

        setDocumentType(result);
    };

    const getAplicaciones = async () => {
        const body = {
            service: "AplicacionGet",
            params: {
                listAll: 'Y'
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setAplicaciones(result.filter(i => i.aplicacionTenantNombre != null));
    };

    async function retrieveEndUsersNoUser(usuarioNoUsuarioId) {
        const body = {
            service: "UsuarioNoUsuarioGet",
            params: {
                usuarioNoUsuarioId,
            },
        };

        const data = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        if (data.length > 0) {
            downloadIMG({
                selfie: data[0].imagePathSELFIE,
                dniFrente: data[0].imagePathDNIFRE,
                dniDorso: data[0].imagePathDNIDOR,
                recorte: data[0].imagePathDNIFTO,
            });

            setUserNoUser(data[0]);
        }
    }

    useEffect(() => {
        if (!isLoading) {
            setRecorte("");
            setUserNoUser({});
            document.getElementById("selfie").value = "";
            document.getElementById("frenteDNI").value = "";
            document.getElementById("dorsoDNI").value = "";
        }

        if (recorteRef.current) recorteRef.current.style.transform = frenteRef.current.style.transform;
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const asyncUseEffect = async () => {
            if (credentials) {
                await getPaises();
                await getAplicaciones();
                const no_user_id = searchParams.get("no_user_id");
                if (no_user_id) {
                    await retrieveEndUsersNoUser(no_user_id);
                }
            }
            setIsLoading(false);
        };
        asyncUseEffect();
    }, [credentials]);

    useEffect(() => {
        if (userNoUser.paisId) getDocumentType(userNoUser.paisId, userNoUser.tipoDeDocumentoId);
    }, [userNoUser]);

    useEffect(() => {
        if (userInfo) {
            setModalDetails({
                isOpen: true,
                action: ACTIONS.NO_USER_DATA_CHECK,
            });
        }
    }, [userInfo]);

    const handleErrorCheck = () => {
        let errorsPartial = {};

        // images existence check
        for (const img in images) {
            if (!images[img]) {
                errorsPartial = {
                    ...errorsPartial,
                    [img]: `Debe agregar imagen ${img}`,
                };
            } else {
                setErrors({});
            }
        }

        setErrors(errorsPartial);

        return errorsPartial;
    };

    const handleSubmit = async ({ numeroDocumento, sexo, country, documentType, application }) => {
        const result = handleErrorCheck();

        if (Object.keys(result).length > 0) return;

        setInRequest(true);
        setPaisSelected(country);

        // execute check against RENAPER in case user is from ARG
        if (country === countryList.ARG.cod) {
            const body = {
                service: "RENAPEROnboardManualCheck",
                params: {
                    usuarioNroDeDocumento: numeroDocumento,
                    usuarioSexo: sexo,
                    documentoFotoImg: images.recorte
                        ? images.recorte.split("data:image/jpeg;base64,")[1]
                        : recorte
                            ? recorte.split("data:image/jpeg;base64,")[1]
                            : "",
                },
            };

            const renaperResponse = await RequestServiceGet(
                body,
                credentials,
                setSnackbarInfo
            );

            if (renaperResponse.length) {
                setUserInfo({
                    aplicacionCod: application,
                    paisSelected: paises.find((pais) => pais.paisCod === country).paisId,
                    renaperResponse: renaperResponse,
                    userInfo: {
                        ...userNoUser,
                        tipoDeDocumentoId: parseInt(documentType),
                        numeroDocumento,
                        sexo
                    },
                    refs: {
                        frenteRef,
                        dorsoRef,
                        selfieRef,
                        recorteRef,
                    },
                    images: { ...images },
                });
            }
        } else {
            setUserInfo({
                aplicacionCod: application,
                paisSelected: paises.find((pais) => pais.paisCod === country).paisId,
                userInfo: {
                    ...userNoUser,
                    tipoDeDocumentoId: parseInt(documentType),
                    numeroDocumento,
                    sexo
                },
                refs: {
                    frenteRef,
                    dorsoRef,
                    selfieRef,
                    recorteRef,
                },
                images: { ...images },
            });
        }
        setInRequest(false);
    };

    return (
        <section className={styles.section}>
            {isLoading || !images ? (
                <CircularIndeterminate />
            ) : (
                <>
                    <TransitionModal
                        confirmationModal={
                            modalDetails.action ===
                            ACTIONS.NO_USER_DATA_CHECK ?? true
                        }
                    >
                        {modalDetails.action === ACTIONS.CROP && (
                            <ModalCropImage
                                image={images.dniFrente}
                                imageRef={frenteRef}
                                images={images}
                                setRecorte={setRecorte}
                                setImages={setImages}
                            />
                        )}
                        {modalDetails.action === ACTIONS.NO_USER_DATA_CHECK && (
                            <OnBoardingManualForm
                                country_cod={paisSelected}
                                params={userInfo}
                            />
                        )}
                    </TransitionModal>

                    <div className={styles.container}>
                        <div className={styles.column}>
                            <div className={styles.imageRow}>
                                <div className={styles.imageContainer}>
                                    {images.dniFrente ? (
                                        <span className={styles.imageRotate}>
                                            <img
                                                src={images.dniFrente}
                                                alt="Frente DNI"
                                                ref={frenteRef}
                                                className={styles.dni}
                                                onClick={() =>
                                                    document
                                                        .getElementById(
                                                            "frenteDNI"
                                                        )
                                                        .click()
                                                }
                                                onChange={handleErrorCheck}
                                            />
                                            <img
                                                alt="rotate"
                                                src={rotar}
                                                className={styles.rotar}
                                                onClick={() =>
                                                    handleRotate(
                                                        frenteRef,
                                                        recorteRef
                                                    )
                                                }
                                            />
                                        </span>
                                    ) : (
                                        <label
                                            role="button"
                                            className={styles.dniBtn}
                                            htmlFor="frenteDNI"
                                        >
                                            Subir frente del DNI
                                        </label>
                                    )}
                                    <input
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, image/jpg"
                                        id="frenteDNI"
                                        onChange={(e) =>
                                            handleImg(e, "dniFrente")
                                        }
                                        type="file"
                                    />
                                </div>

                                <div className={styles.imageContainer}>
                                    {images.dniDorso ? (
                                        <span className={styles.imageRotate}>
                                            <img
                                                src={images.dniDorso}
                                                alt="Dorso DNI"
                                                ref={dorsoRef}
                                                className={styles.dni}
                                                onClick={() =>
                                                    document
                                                        .getElementById(
                                                            "dorsoDNI"
                                                        )
                                                        .click()
                                                }
                                                onChange={handleErrorCheck}
                                            />
                                            <img
                                                alt="rotate"
                                                src={rotar}
                                                className={styles.rotar}
                                                onClick={() =>
                                                    handleRotate(dorsoRef)
                                                }
                                            />
                                        </span>
                                    ) : (
                                        <label
                                            role="button"
                                            className={styles.dniBtn}
                                            htmlFor="dorsoDNI"
                                        >
                                            Subir dorso del DNI
                                        </label>
                                    )}
                                    <input
                                        style={{ display: "none" }}
                                        accept="image/png, image/jpeg, image/jpg"
                                        id="dorsoDNI"
                                        onChange={(e) =>
                                            handleImg(e, "dniDorso")
                                        }
                                        type="file"
                                    />
                                </div>
                            </div>

                            <div className={styles.imageRow}>
                                <div className={styles.imageContainer}>
                                    {images.selfie ? (
                                        <span
                                            className={styles.imageRotateSelfie}
                                        >
                                            <img
                                                src={images.selfie}
                                                alt="Selfie"
                                                ref={selfieRef}
                                                className={styles.selfie}
                                                onChange={handleErrorCheck}
                                                onClick={() =>
                                                    document
                                                        .getElementById(
                                                            "selfie"
                                                        )
                                                        .click()
                                                }
                                            />
                                            <img
                                                alt="rotate"
                                                className={styles.rotar}
                                                onClick={() =>
                                                    handleRotate(selfieRef)
                                                }
                                                src={rotar}
                                            />
                                        </span>
                                    ) : (
                                        <label
                                            className={styles.selfieBtn}
                                            htmlFor="selfie"
                                            role="button"
                                        >
                                            Subir selfie
                                        </label>
                                    )}

                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        id="selfie"
                                        onChange={(e) => handleImg(e, "selfie")}
                                        style={{ display: "none" }}
                                        type="file"
                                    />
                                </div>

                                {(images.recorte || recorte) && (
                                    <div className={styles.imageContainer}>
                                        <img
                                            src={
                                                images.recorte
                                                    ? images.recorte
                                                    : recorte
                                            }
                                            alt="Foto DNI"
                                            ref={recorteRef}
                                            className={styles.recorte}
                                            onClick={() => {
                                                setModalDetails({
                                                    isOpen: true,
                                                    action: ACTIONS.CROP,
                                                });
                                            }}
                                            onChange={handleErrorCheck}
                                        />
                                    </div>
                                )}

                                {images.dniFrente &&
                                    !images.recorte &&
                                    !recorte && (
                                        <div className={styles.imageContainer}>
                                            <Button texto="Recortar imagen del DNI"
                                                onClick={() => {
                                                    setModalDetails({
                                                        isOpen: true,
                                                        action: ACTIONS.CROP,
                                                    });
                                                }} />
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div className={styles.columnRenaper}>
                            <h2 className={styles.title}>Chequear datos</h2>

                            <Formik
                                initialValues={{
                                    country: userNoUser.paisCod || "",
                                    documentType: documentType ? userNoUser.tipoDeDocumentoId : "",
                                    numeroDocumento: userNoUser.usuarioNoUsuarioNroDeDocumentoX || "",
                                    sexo: userNoUser.usuarioNoUsuarioSexo || "M",
                                    application: userNoUser.aplicacionCod || APLICACION_COD,
                                }}
                                validationSchema={Yup.object({
                                    country:
                                        Yup.string().required(
                                            "Seleccione un país"
                                        ),
                                    documentType: Yup.string().required(
                                        "Seleccione un tipo de documento"
                                    ),
                                    numeroDocumento: Yup.string()
                                        .test(
                                            "match",
                                            "Documento inválido",
                                            (value) =>
                                                String(value).match(
                                                    documentRegex
                                                )
                                        )
                                        .required(
                                            "Número de documento es requerido"
                                        ),
                                    sexo: Yup.string()
                                        .oneOf(["X", "M", "F"])
                                        .required("Elija un sexo"),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    try {
                                        handleSubmit(values);
                                    } catch (e) {
                                        setSnackbarInfo({
                                            message: e.errmsg,
                                            severity: "error",
                                            open: true,
                                        });
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ values, setValues }) => (
                                    <Form className={styles.form} noValidate>
                                        <div className={styles.inputDiv}>
                                            <FormikSelectInput
                                                className={styles.input}
                                                disabled={!paises.length}
                                                fullWidth={false}
                                                name="country"
                                                labelText={"País"}
                                                onChange={(e) => {
                                                    setValues((values) => ({
                                                        ...values,
                                                        country: e.target.value,
                                                    }));
                                                    getDocumentType(
                                                        e.target.options[e.target.selectedIndex].dataset.id
                                                    );
                                                }}
                                            >
                                                <option value={""}>
                                                    Seleccione una opción...
                                                </option>
                                                {paises.map(
                                                    ({
                                                        paisId,
                                                        paisNombre,
                                                        paisCod,
                                                    }) => (
                                                        <option
                                                            key={paisId}
                                                            data-id={paisId}
                                                            value={paisCod}
                                                        >
                                                            {paisNombre}
                                                        </option>
                                                    )
                                                )}
                                            </FormikSelectInput>

                                            <FormikSelectInput
                                                className={styles.input}
                                                disabled={!documentType.length}
                                                fullWidth={false}
                                                name="documentType"
                                                labelText={"Tipo de documento"}
                                                onChange={(e) => {
                                                    setValues((values) => ({
                                                        ...values,
                                                        documentType: e.target.value,
                                                    }));
                                                    setDocumentRegex(
                                                        documentType.filter((doc) =>
                                                            String(doc.tipoDeDocumentoId) === e.target.value
                                                        )[0].tipoDeDocumentoRegExp
                                                    );
                                                }}
                                            >
                                                <option value={""}>
                                                    Seleccione una opción...
                                                </option>
                                                {documentType.map(
                                                    ({
                                                        tipoDeDocumentoId,
                                                        tipoDeDocumentoCod,
                                                        tipoDeDocumentoNombre,
                                                    }) => (
                                                        <option
                                                            key={tipoDeDocumentoCod}
                                                            value={tipoDeDocumentoId}
                                                        >
                                                            {tipoDeDocumentoNombre}
                                                        </option>
                                                    )
                                                )}
                                            </FormikSelectInput>

                                            <FormikTextInput
                                                className={styles.input}
                                                fullWidth={false}
                                                label="Documento"
                                                name="numeroDocumento"
                                                placeholder="Ingrese número de documento"
                                                margin="normal"
                                            />

                                            <FormControl
                                                className={styles.formControl}
                                            >
                                                <FormLabel
                                                    style={{ fontSize: "0.9rem" }}
                                                >
                                                    Sexo
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    className={styles.radio}
                                                    onChange={(e) =>
                                                        setValues((values) => ({
                                                            ...values,
                                                            sexo: e.target.value,
                                                        }))
                                                    }
                                                    value={values.sexo}
                                                >
                                                    <FormControlLabel
                                                        value="X"
                                                        control={<Radio />}
                                                        label="No Binario"
                                                    />
                                                    <FormControlLabel
                                                        value="F"
                                                        control={<Radio />}
                                                        label="Femenino"
                                                    />
                                                    <FormControlLabel
                                                        value="M"
                                                        control={<Radio />}
                                                        label="Masculino"
                                                    />
                                                </RadioGroup>
                                            </FormControl>

                                            <FormikSelectInput
                                                className={styles.input}
                                                disabled={!aplicaciones.length}
                                                fullWidth={false}
                                                name="application"
                                                labelText={"Aplicación"}
                                            >
                                                <option value={""}>
                                                    Seleccione una opción...
                                                </option>
                                                {aplicaciones.map(
                                                    ({
                                                        aplicacionId,
                                                        aplicacionCod,
                                                        aplicacionNombre,
                                                        aplicacionTenantNombre,
                                                    }) => (
                                                        <option
                                                            key={aplicacionId}
                                                            value={aplicacionCod}
                                                        >
                                                            {aplicacionNombre} ({aplicacionTenantNombre})
                                                        </option>
                                                    )
                                                )}
                                            </FormikSelectInput>
                                        </div>

                                        <div className={styles.buttonRow}>
                                            <Button
                                                disabled={inRequest}
                                                className={styles.button}
                                                texto={"Chequear datos"}
                                            />

                                            {Object.keys(errors).length
                                                ? Object.keys(errors).map((error, index) => (
                                                    <p
                                                        key={index}
                                                        className={styles.error}
                                                    >
                                                        {errors[error]}
                                                    </p>
                                                ))
                                                : null}
                                        </div>

                                        {inRequest && (
                                            <div
                                                className={styles.checkingData}
                                            >
                                                <p>Chequeando datos...</p>
                                                <CircularIndeterminate
                                                    customMargin={"0em"}
                                                />
                                            </div>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}
