import React, { useEffect, useState } from "react";

import { conexion as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton } from "components";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    routeInfo,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Conexion({
    usuarioId = null,
    tipoDeDocumentoId = null,
    usuarioNroDeDocumentoX = null,
    usuarioSexo = null
}) {
    const credentials = useRecoilValue(userCredentials);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [isLoading, setIsLoading] = useState(false);
    const [conexionLocalState, setConexionLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatConexion();
    };

    const formatConexion = async () => {
        setIsLoading(true);

        const body = {
            service: "MerchantServicioUsuarioMinimoGet",
            params: {
                usuarioId,
                tipoDeDocumentoId,
                usuarioNroDeDocumentoX,
                usuarioSexo
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const conexionPartial = result.map(item => {
            return {
                id: item.merchantServicioUsuarioId,
                [titles[0]]: item.merchantNombre,
                [titles[1]]: item.servicioNombre,
                [titles[2]]: item.relacionTipo,
                [titles[3]]: item.rolNombre,
                [titles[4]]: item.relacionStatus,
            };
        });

        setConexionLocalState(conexionPartial);

        setIsLoading(false);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        if (usuarioId == null) setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <Buttons>
                <HeaderButton
                    text={"Actualizar página"}
                    icon={reload}
                    updateList={handleUpdateTable}
                />
            </Buttons>

            <XGridDemo
                rows={conexionLocalState}
                titles={titles}
                loading={isLoading}
                setSecondarySelected={() => { }}
                secondarySelected={{ row: null }}
            />
        </section>
    );
}
