import React from "react";

import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { Button } from "components";

import { Formik, Form } from "formik";

import { useSetRecoilState } from "recoil";
import { snackbarData } from "recoil/GlobalState";

import styles from "styles/pages/usuariosNoUsuarios/UsuariosNoUsuariosFilters.module.scss";

export default function UsuariosNoUsuariosFilters({ handleSubmit, setFilters, filters }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    return (
        <Formik
            initialValues={{
                usuarioNroDeDocumentoX: filters.usuarioNroDeDocumentoX || "",
            }}
            onSubmit={(
                { usuarioNroDeDocumentoX },
                { setSubmitting }
            ) => {
                try {
                    const params = {
                        usuarioNroDeDocumentoX: usuarioNroDeDocumentoX?.replace(
                            /\./g,
                            ""
                        ) || ''
                    };

                    setFilters(params);
                    handleSubmit(params);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.form} noValidate>
                <FormikTextInput
                    className={styles.input}
                    placeholder={"Documento"}
                    name="usuarioNroDeDocumentoX"
                />
                <Button texto={"Buscar"} />
            </Form>
        </Formik >
    );
}
