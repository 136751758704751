import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { Form, Formik } from "formik";

import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";
import { transformDate } from "utils/Local-services";

export default function DeleteModal({ handleUpdateTable }) {
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const setModalDetails = useSetRecoilState(modalData);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const handleSubmit = async ({ usuarioNoUsuarioId }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "UsuarioNoUsuarioDel",
            params: {
                usuarioNoUsuarioId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.DELETE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                usuarioNoUsuarioId: row.row.id,
            }}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    console.error(e);
                    return e;
                    // return error modal
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <label className={styles.label}>
                        ¿Está seguro que desea eliminar este Usuario No Usuario?
                    </label>

                    <input name="usuarioNoUsuarioId" id="usuarioNoUsuarioId" type="hidden" />
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <th>Tipo de Documento</th>
                                <td>{row.row.tipoDeDocumentoCod} - {row.row.tipoDeDocumentoNombre}</td>
                            </tr>
                            <tr>
                                <th>Documento</th>
                                <td>{row.row.usuarioNoUsuarioNroDeDocumentoX}</td>
                            </tr>
                            <tr>
                                <th>Sexo</th>
                                <td>
                                    {row.row.usuarioNoUsuarioSexo === "M"
                                        ? "Masculino"
                                        : row.row.usuarioNoUsuarioSexo === "F"
                                            ? "Femenino"
                                            : "No Binario"}
                                </td>
                            </tr>
                            <tr>
                                <th>Fecha de Nacimiento</th>
                                <td>{row.row.usuarioNoUsuarioFechaDeNacimiento ? transformDate(row.row.usuarioNoUsuarioFechaDeNacimiento.slice(0, 10)) : ''}</td>
                            </tr>
                        </tbody>
                    </table>

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Confirmar" }}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
