import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";

import { Checkbox, Grid } from "@mui/material";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { onBoardingManual } from "services/mia-services/onBoarding/onBoardingManual";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ROUTES } from "consts/routes";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, snackbarData, modalData } from "recoil/GlobalState";

import { FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import CircularIndeterminate from "components/UI/Spinner/Spinner";

import styles from "styles/pages/usuariosNoUsuarios/PreonboardingManualForm.module.scss";

export default function OnBoardingManualFormArgentina({ params }) {
    const [isLoading, setIsLoading] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [match, setMatch] = useState(60);
    const [checkboxes, setCheckboxes] = useState({
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
    });

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const credentials = useRecoilValue(userCredentials);

    const imagenSelfie = useRef(null);
    const imagenFrenteDNI = useRef(null);
    const imagenDorsoDNI = useRef(null);
    const imagenRecorte = useRef(null);

    const { aplicacionCod, renaperResponse, userInfo, refs, images, paisSelected } = params;

    const renaperCP = renaperResponse.length > 0 ? renaperResponse[0].domicilioCPA || null : null;

    const { selfie, dniDorso, dniFrente, recorte } = images;

    const { dorsoRef, selfieRef, frenteRef, recorteRef } = refs;

    const getProvinces = async () => {
        const bodyProvincia = {
            service: "ProvinciaGet",
            params: {
                provinciaId: null,
                paisId: paisSelected,
                provinciaCod: null,
                provinciaNombre: null,
            },
        };

        const provinciasResponse = await RequestServiceGet(
            bodyProvincia,
            credentials,
            setSnackbarInfo
        );
        setProvincias(provinciasResponse);
    };

    const getLocalidades = async (localidadId = null) => {
        const body = {
            service: "LocalidadGet",
            params: {
                localidadId: localidadId,
                localidadCod: null,
                localidadNombre: null,
            },
        };

        const localidadResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setLocalidades(localidadResponse);
    };

    useEffect(() => {
        const localidadId =
            renaperResponse[0].localidadId !== 1
                ? renaperResponse[0].localidadId
                : null;

        getProvinces();
        getLocalidades(localidadId);
    }, []);

    async function handleSubmit(values, setErrors) {
        for (const checkbox in checkboxes) {
            if (!checkboxes[checkbox]) {
                setErrors({
                    checkbox:
                        "Todos los checkbox deben estar tildados si los datos son correctos.",
                });
                return;
            }
        }

        setIsLoading((isLoading) => !isLoading);

        const args = {
            aplicacionCod: aplicacionCod,
            tipoDeDocumentoId: values.documentTypeId,
            usuarioNroDeDocumentoX: String(values.documentNumber),
            usuarioSexo: values.gender,
            usuarioFechaDeNacimiento: values.birthdate,
            usuarioApellidos: values.lastname,
            usuarioNombres: values.name,
            usuarioVtoDeDocumento: values.vencimientoDNI,
            domicilioCalle: values.street,
            domicilioCasa: values.streetNumber,
            domicilioPiso: values.aptFloor,
            domicilioUnidad: values.aptUnit,
            domicilioCPA: values.CP,
            domicilioScan: renaperResponse[0].domicilioScan,
            usuarioCelular: values.phoneNumber,
            usuarioMail: values.email,
            documentoFrenteImg: dniFrente.split("data:image/jpeg;base64,")[1],
            documentoDorsoImg: dniDorso.split("data:image/jpeg;base64,")[1],
            documentoFotoImg: recorte.split("data:image/jpeg;base64,")[1],
            usuarioFotoImg: selfie.split("data:image/jpeg;base64,")[1],
            usuarioDNIMatchScore: match,
            usuarioCUIT: values.CuitNumber,
            usuarioDNITramite: values.tramite,
            usuarioDNITramiteRENAPER: values.tramiteRENAPER,
            usuarioDNIMatchRENAPER: parseFloat(values.matchRENAPER),
            localidadId: values.localidadId,
            usuarioPaisNacionalidadId: values.usuarioPaisNacionalidadId
        };

        // execute check against RENAPER in case user is from ARG
        await onBoardingManual(args, credentials, setSnackbarInfo);

        setTimeout(() => {
            window.location.assign(ROUTES.no_usuarios.route);
        }, 2500);

        setModalDetails((modalState) => !modalState);

        setIsLoading((isLoading) => !isLoading);
    }

    useEffect(() => {
        if (
            imagenSelfie.current &&
            imagenFrenteDNI.current &&
            imagenDorsoDNI.current &&
            imagenRecorte.current
        ) {
            imagenSelfie.current.style.transform =
                selfieRef.current.style.transform;
            imagenFrenteDNI.current.style.transform =
                frenteRef.current.style.transform;
            imagenDorsoDNI.current.style.transform =
                dorsoRef.current.style.transform;
            imagenRecorte.current.style.transform =
                recorteRef.current.style.transform;
        }
    }, [selfieRef, frenteRef, dorsoRef, recorteRef]);

    return !localidades.length || !provincias.length ? (
        <CircularIndeterminate />
    ) : (
        <Formik
            initialValues={{
                name: renaperResponse[0].usuarioNombres,
                lastname: renaperResponse[0].usuarioApellidos,
                birthdate: renaperResponse[0].usuarioFechaDeNacimiento,
                tramiteRENAPER: renaperResponse[0].usuarioDNITramite,
                tramite: "",
                vencimientoDNI: renaperResponse[0].usuarioVtoDeDocumento,
                documentTypeId: userInfo.tipoDeDocumentoId,
                documentNumber: renaperResponse[0].usuarioNroDeDocumento,
                gender: renaperResponse[0].usuarioSexo,
                phoneNumber: userInfo.usuarioNoUsuarioTelefonoCelular || "",
                email: userInfo.usuarioNoUsuarioMail || renaperResponse[0].usuarioNroDeDocumento+'@miaid.me',
                CuitNumber: renaperResponse[0].usuarioCUIT,
                matchRENAPER: renaperResponse[0].usuarioDNIMatchRENAPER,
                provinciaId: renaperResponse[0].localidadId !== 1
                    ? localidades[0]?.provinciaId
                    : provincias[0]?.provinciaId,
                localidadId: renaperResponse[0].localidadId,
                street: renaperResponse[0].domicilioCalle,
                streetNumber: renaperResponse[0].domicilioCasa,
                aptFloor: renaperResponse[0].domicilioPiso,
                CP: renaperResponse[0].domicilioCPA,
                usuarioPaisNacionalidadId: renaperResponse[0].usuarioPaisNacionalidadId,
                checkbox: "",
            }}
            validationSchema={Yup.object({
                email: Yup.string(FORM_ERROR_MESSAGES.text)
                    .matches(
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        "Formato inválido"
                    ).required(
                        FORM_ERROR_MESSAGES.required
                    ),
                phoneNumber: Yup.number(FORM_ERROR_MESSAGES.number),
                tramite: Yup.number(FORM_ERROR_MESSAGES.number).required(
                    FORM_ERROR_MESSAGES.required
                ),
                CuitNumber: Yup.string(FORM_ERROR_MESSAGES.number).required(
                    FORM_ERROR_MESSAGES.required
                ),
                matchRENAPER: Yup.number(FORM_ERROR_MESSAGES.number).min(
                    0.4,
                    "No match"
                ),
                CP: renaperCP ? Yup.string(FORM_ERROR_MESSAGES.text) : Yup.string(FORM_ERROR_MESSAGES.text)
                    .matches(
                        /^[A-Z][0-9]{4}[A-Z]{3}$/,
                        "Formato inválido"
                    ).required(
                        FORM_ERROR_MESSAGES.required
                    )
            })}
            onSubmit={(values, { setErrors, setSubmitting }) => {
                try {
                    handleSubmit(values, setErrors);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors }) => (
                <Form className={styles.inputs} noValidate>
                    <Grid
                        container
                        columns={12}
                        spacing={0.5}
                        sx={{ justifyContent: "center", width: "90%" }}
                    >
                        <Grid
                            item
                            sm={12}
                            display={"flex"}
                            gap={"5em"}
                            justifyContent={"center"}
                        >
                            <div className={styles.userImage}>
                                <span className={styles.imageWrapper}>
                                    <img
                                        alt="Selfie"
                                        className={styles.image}
                                        ref={imagenSelfie}
                                        src={selfie}
                                        onClick={() => window.open(selfie)}
                                    />
                                </span>
                                <p>Selfie</p>
                            </div>

                            <div className={styles.userImage}>
                                <span className={styles.imageWrapper}>
                                    <img
                                        alt={"Frente del DNI"}
                                        className={styles.image}
                                        ref={imagenFrenteDNI}
                                        src={dniFrente}
                                    />
                                </span>
                                <p>Frente del DNI</p>
                            </div>

                            <div className={styles.userImage}>
                                <span className={styles.imageWrapper}>
                                    <img
                                        alt="Dorso del DNI"
                                        className={styles.image}
                                        ref={imagenDorsoDNI}
                                        src={dniDorso}
                                    />
                                </span>
                                <p>Dorso del DNI</p>
                            </div>

                            <div className={styles.userImage}>
                                <span className={styles.imageWrapper}>
                                    <img
                                        alt="Recorte"
                                        className={styles.image}
                                        ref={imagenRecorte}
                                        src={recorte}
                                    />
                                </span>
                                <p>Recorte del DNI</p>
                            </div>
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                helperText={
                                    "Marque el checkbox si los datos son correctos"
                                }
                                labelText={"Nombres"}
                                name="name"
                                type={"text"}
                            />

                            <Checkbox
                                className={styles.checkbox}
                                color="primary"
                                checked={checkboxes.first}
                                onChange={(e) =>
                                    setCheckboxes({
                                        ...checkboxes,
                                        first: e.target.checked,
                                    })
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                helperText={
                                    "Marque el checkbox si los datos son correctos"
                                }
                                labelText={"Apellidos"}
                                name="lastname"
                                type={"text"}
                            />

                            <Checkbox
                                className={styles.checkbox}
                                color="primary"
                                checked={checkboxes.second}
                                onChange={(e) =>
                                    setCheckboxes({
                                        ...checkboxes,
                                        second: e.target.checked,
                                    })
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                helperText={
                                    "Marque el checkbox si los datos son correctos"
                                }
                                labelText={"Fecha de nacimiento"}
                                name="birthdate"
                                type={"date"}
                            />

                            <Checkbox
                                className={styles.checkbox}
                                color="primary"
                                checked={checkboxes.third}
                                onChange={(e) =>
                                    setCheckboxes({
                                        ...checkboxes,
                                        third: e.target.checked,
                                    })
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                helperText={
                                    "Marque el checkbox si los datos son correctos"
                                }
                                labelText={"Trámite RENAPER"}
                                name="tramiteRENAPER"
                                type={"text"}
                            />

                            <Checkbox
                                className={styles.checkbox}
                                color="primary"
                                checked={checkboxes.fourth}
                                onChange={(e) =>
                                    setCheckboxes({
                                        ...checkboxes,
                                        fourth: e.target.checked,
                                    })
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={false}
                                helperText={""}
                                labelText={"Trámite"}
                                name="tramite"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                helperText={
                                    "Marque el checkbox si los datos son correctos"
                                }
                                name="vencimientoDNI"
                                labelText={"Fecha vencimiento DNI"}
                                type={"date"}
                            />
                            <Checkbox
                                className={styles.checkbox}
                                color="primary"
                                checked={checkboxes.fifth}
                                onChange={(e) =>
                                    setCheckboxes({
                                        ...checkboxes,
                                        fifth: e.target.checked,
                                    })
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={true}
                                labelText={"Número de documento"}
                                name="documentNumber"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikSelectInput
                                disabled={true}
                                fullWidth={true}
                                labelText={"Sexo"}
                                name="gender"
                            >
                                <option value="">
                                    Seleccione una opción...
                                </option>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                                <option value="X">No Binario</option>
                            </FormikSelectInput>
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={false}
                                labelText={"Email"}
                                name="email"
                                type={"email"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={false}
                                labelText={"Número de telefono"}
                                name="phoneNumber"
                                type={"tel"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                disabled={
                                    renaperResponse[0].usuarioCUIT.split(
                                        "-"
                                    )[0] !== "11"
                                }
                                labelText={"CUIT"}
                                name="CuitNumber"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className={styles.input}
                                // disabled={true}
                                labelText={"Match RENAPER"}
                                name="matchRENAPER"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikSelectInput
                                disabled={
                                    renaperResponse[0].localidadId !== 1 ||
                                    !provincias.length
                                }
                                fullWidth={true}
                                labelText={"Provincia"}
                                name="provinciaId"
                            >
                                <option value="">
                                    Seleccione una opción...
                                </option>
                                {provincias?.map(
                                    ({ provinciaId, provinciaNombre }) => (
                                        <option
                                            key={provinciaId}
                                            value={provinciaId || ""}
                                        >
                                            {provinciaNombre}
                                        </option>
                                    )
                                )}
                            </FormikSelectInput>
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikSelectInput
                                disabled={
                                    renaperResponse[0].localidadId !== 1 ||
                                    !localidades.length
                                }
                                fullWidth={true}
                                labelText={"Localidad"}
                                name="localidadId"
                            >
                                <option value="">
                                    Seleccione una opción...
                                </option>
                                {localidades?.map(
                                    ({ localidadId, localidadNombre }) => (
                                        <option
                                            key={localidadId}
                                            value={localidadId || ""}
                                        >
                                            {localidadNombre}
                                        </option>
                                    )
                                )}
                            </FormikSelectInput>
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className="column"
                                disabled={true}
                                labelText={"Calle"}
                                name="street"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className="column"
                                disabled={true}
                                labelText={"Número"}
                                name="streetNumber"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className="column"
                                disabled={true}
                                labelText={"Piso"}
                                name="aptFloor"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={3}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FormikTextInput
                                className="column"
                                disabled={!!renaperCP}
                                labelText={"Código postal"}
                                name="CP"
                                type={"text"}
                            />
                        </Grid>

                        <Grid
                            item
                            sm={10}
                            display={"flex"}
                            alignItems={"center"}
                            gap={"2em"}
                            justifyContent={"center"}
                            marginTop={'1em'}
                        >

                        </Grid>

                        <FormButton
                            inRequest={isLoading}
                            newFormData={{ button: "Aprobar" }}
                        />

                        {isLoading && <CircularIndeterminate />}

                        {errors.checkbox && (
                            <ErrorMessage
                                className={styles.errorMessage}
                                component={"p"}
                                name="checkbox"
                            />
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
