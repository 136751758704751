import React, { useEffect, useState } from "react";

import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Formik, Form } from "formik";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoil/GlobalState";

import { Button } from "components";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/usuariosBO/UsuariosBOFilters.module.scss";

import { usuarioStatus } from "consts/usuarioStatus";

export default function UserBOFilters({
    handleSubmit,
    setFilterParams,
    filterParams,
}) {
    const [paises, setPaises] = useState([]);
    const [registros, setRegistros] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    function handleReset(setValues) {
        setValues({
            lastname: "",
            documentNumber: "",
            registroId: "",
            usuarioStatusCod: "",
            paisId: "",
        });
    }

    async function getRegistros() {
        const body = {
            service: "RegistroGet",
            params: {
                tipoRegistroCod: "E",
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setRegistros(result);
    }

    const getCountries = async () => {
        const bodyPais = {
            service: "MIAPaisGet",
            params: { paisConBandera: 'N' },
        };

        const paisesResponse = await RequestServiceGet(
            bodyPais,
            credentials,
            setSnackbarInfo
        );
        setPaises(paisesResponse);
    };

    useEffect(() => {
        if (credentials) {
            getCountries();
            getRegistros();
        }
    }, [credentials]);

    return (
        <Formik
            initialValues={{
                lastname: filterParams.usuarioApellido || "",
                documentNumber: filterParams.usuarioNroDeDocumentoX || "",
                registroId: filterParams.registroId || "",
                usuarioStatusCod: filterParams.usuarioStatusCod || "",
                paisId: filterParams.paisId || "",
            }}
            onSubmit={(
                { lastname, documentNumber, registroId, usuarioStatusCod, paisId },
                { setSubmitting }
            ) => {
                try {
                    const params = {
                        usuarioApellidos: lastname,
                        usuarioNroDeDocumentoX: documentNumber.replace(
                            /\./g,
                            ""
                        ),
                        registroId,
                        usuarioStatusRegistro: usuarioStatusCod,
                        paisId,
                    };

                    setFilterParams(params);
                    handleSubmit(params);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.message,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ setValues }) => (
                <Form className={styles.form} noValidate>
                    <FormikTextInput
                        className={styles.input}
                        fullWidth={true}
                        placeholder={"Apellido"}
                        name="lastname"
                    />

                    <FormikTextInput
                        className={styles.input}
                        fullWidth={true}
                        placeholder={"Documento"}
                        name="documentNumber"
                    />

                    <FormikSelectInput
                        className={styles.input}
                        disabled={!registros.length}
                        fullWidth={true}
                        name="registroId"
                    >
                        <option value="">Registro</option>
                        {registros?.map(({ registroId, registroNombre }) => (
                            <option key={registroId} value={registroId}>
                                {registroNombre}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <FormikSelectInput
                        className={styles.input}
                        disabled={!usuarioStatus.length}
                        fullWidth={true}
                        name="usuarioStatusCod"
                    >
                        <option value="">Status</option>
                        {usuarioStatus?.map((usuarioStatusCod) => (
                            <option key={usuarioStatusCod} value={usuarioStatusCod}>
                                {usuarioStatusCod === 'OK' ? 'W' : usuarioStatusCod}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <FormikSelectInput
                        className={styles.input}
                        disabled={!paises.length}
                        fullWidth={true}
                        name="paisId"
                    >
                        <option value="">País</option>
                        {paises?.map(({ paisId, paisNombre }) => (
                            <option key={paisId} value={paisId}>
                                {paisNombre}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <Button texto={"Buscar"} />

                    <Button
                        texto={"Reiniciar Filtros"}
                        type={"button"}
                        onClick={() => handleReset(setValues)}
                    />
                </Form>
            )}
        </Formik>
    );
}
