import React from "react";

import Conexion from "pages/conexiones/Conexion";

export default function ConnectionModal({
    tipoDeDocumentoId,
    usuarioNroDeDocumentoX,
    usuarioSexo
}) {
    return (
        <div style={{ width: '100%' }}>
            <Conexion
                tipoDeDocumentoId={tipoDeDocumentoId}
                usuarioNroDeDocumentoX={usuarioNroDeDocumentoX}
                usuarioSexo={usuarioSexo}
            />
        </div>
    );
}
