import moment from "moment";

import { RequestServiceActions } from "../RequestServiceActions";

import { ACTIONS } from "consts/actions";
import { X_DUMMY_TOKEN, X_API_KEY_TOKEN } from "consts/env";

export const onBoardingManual = async (args, credentials, setSnackbarInfo) => {
    const dummyToken = window.btoa(
        JSON.stringify({
            before: moment().add(10, "h").format("YYYY-MM-DD HH:mm:ss"),
            after: moment().subtract(10, "h").format("YYYY-MM-DD HH:mm:ss"),
            token: X_DUMMY_TOKEN,
        })
    );

    const body = {
        // service: "OnboardManual",
        service: "MIAEOnboardManualDo",
        params: {
            tipoDeDocumentoId: args.tipoDeDocumentoId || null,
            usuarioNroDeDocumentoX: args.usuarioNroDeDocumentoX || null,
            usuarioSexo: args.usuarioSexo || null,
            usuarioFechaDeNacimiento: args.usuarioFechaDeNacimiento || null,
            usuarioApellidos: args.usuarioApellidos || null,
            usuarioNombres: args.usuarioNombres || null,
            usuarioVtoDeDocumento: args.usuarioVtoDeDocumento || null,
            usuarioDNIMatchScore: args.usuarioDNIMatchScore || null,
            usuarioCUIT: args.usuarioCUIT || null,
            usuarioDNITramite: args.usuarioDNITramite || null,
            usuarioDNITramiteRENAPER: args.usuarioDNITramiteRENAPER || null,
            usuarioDNIMatchRENAPER: args.usuarioDNIMatchRENAPER || null,
            domicilioCalle: args.domicilioCalle || null,
            domicilioCasa: args.domicilioCasa || null,
            domicilioPiso: args.domicilioPiso || null,
            domicilioUnidad: args.domicilioUnidad || null,
            domicilioCPA: args.domicilioCPA || null,
            domicilioScan: args.domicilioScan || null,
            localidadId: args.localidadId || null,
            usuarioCelular: args.usuarioCelular || null,
            usuarioMail: args.usuarioMail || null,
            documentoFrenteImg: args.documentoFrenteImg || null,
            documentoDorsoImg: args.documentoDorsoImg || null,
            documentoFotoImg: args.documentoFotoImg || null,
            usuarioFotoImg: args.usuarioFotoImg || null,
            usuarioPaisNacionalidadId: args.usuarioPaisNacionalidadId || null,
            statusOnBoardDocumento: 'OK',
            aplicacionCod: args.aplicacionCod || null
        },
    };

    const result = await RequestServiceActions(
        body,
        {
            ...credentials,
            xSupplementaryToken: dummyToken,
            xApiKey: X_API_KEY_TOKEN,
            XOriginId: args.aplicacionCod
        },
        ACTIONS.INSERT,
        setSnackbarInfo
    );

    return result;
};
