export const ACTIONS = {
    DELETE: "delete",
    INSERT: "insert",
    UPDATE: "update",
    ERROR: "error",
    DETAIL: "detail",
    SHOW: "show",
    NO_USER_DATA_CHECK: "renaper",
    CROP: "crop",
    MAP_DETAILS: "map_details",
    IMAGE_ZOOM: "image_zoom",
};
