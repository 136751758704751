export const deviceTypes = ["Código", "Nombre"];

export const domicilioTypes = ["Código", "Nombre"];

export const mailTypes = ["Código", "Nombre"];

export const telefonoTypes = ["Código", "Nombre"];

export const condicionAFIP = ["Código", "Nombre", "Nombre AFIP"];

export const registroTypes = ["Código", "Nombre"];

export const identificacionTributariaTypes = ["Código", "Nombre"];

export const params_generales = [
    "Blacklist Match RE",
    "Blacklist Match BO",
    "DNI Match OK",
    "DNI Match BO",
    "RENAPER Match OK",
    "RENAPER Match BO",
    "Fecha",
];

export const imagenFormatTypes = [
    "Código",
    "Nombre",
    "Alto",
    "Ancho",
    "Bucket",
    "Folder",
];

export const imagenVaria = [
    "Código",
    "Nombre",
    "Ancho",
    "Alto",
    "Formato",
    "Tipo",
];

export const imagenTypes = ["Código", "Nombre", "Bucket", "Folder"];

export const imagenFormats = ["Código", "Nombre", "Ancho", "Alto"];

export const imagenParams = ["Código", "Nombre", "Ancho", "Alto"];

export const monedas = ["Código", "Nombre", "Es Base"];

export const monedasSeries = [
    "Código",
    "Nombre",
    "Fecha serie",
    "Q Moneda",
    "Valor Comprador",
    "Valor Vendedor",
];

export const registroEstado = ["Código", "Nombre"];

export const aplicacion = ["Código", "Nombre"];

export const actividadAfip = ["Código", "Descripción", "Detalle"];

export const canal = ["Código", "Nombre"];

export const rol = ["Código", "Nombre"];

export const servicio = ["Código", "Nombre"];

export const registro = [
    "Código",
    "Nombre Tipo Registro",
    "Nombre Registro",
    "Descripción",
    "Requiere BO",
];

export const documentoTypes = [
    "Código",
    "Tipo",
    "Código microblink",
    "Ámbito",
    "País",
    "Provincia",
];

export const pais = [
    "Código",
    "Nombre",
    "Es default",
    "División política",
    "Image path",
];

export const localidad = [
    "Nombre localidad",
    "Nombre provincia",
    "Latitud NE",
    "Longitud NE",
    "Latitud SO",
    "Longitud SO",
    "Latitud PIN",
    "Longitud PIN",
];

export const devices = [
    "Identificación",
    "Tipo",
    "Marca",
    "Modelo",
    "Serie",
    "MAC",
    "GCM",
    "Fecha compra",
    "Cliente",
    "Servicio",
    "Aplicación",
];

export const estadoCivil = ["Código", "Nombre"];

export const factorBiometrico = ["Código", "Nombre"];

export const eventoSesion = ["Código", "Nombre"];

export const rubro = ["Código", "Nombre", "Imagen Path"];

export const param = [
    "Fecha y hora",
    "DNI Match",
    "DNI Match BO",
    "RENAPER Match",
    "RENAPER Match BO",
];

export const zonaNoEnroll = ["Nombre", "Enroll", "Login"];

export const feriados = ["Nombre"];

export const feriadoSerie = ["Nombre", "Fecha", "Es comercial", "Es bancario"];

export const entidadFinanciera = ["Nombre", "CUIT", "Nombre Tarjeta"];

export const usuariosNoUsuarios = [
    "Documento",
    "Sexo",
    "Fecha de Nacimiento",
    "Celular",
    "Mail",
    "Nombre de Aplicación",
    "Fecha",
];

export const usuariosBO = [
    "Apellidos",
    "Nombres",
    "Pais",
    "Tipo",
    "Documento",
    "Sexo",
    "Fecha de registro",
    "Status de registro",
];

export const usuarios = [
    "Nombre",
    "Apellidos",
    "País",
    "Documento",
    "Sexo",
    "Mail",
    "Teléfono",
    "Fecha Alta",
    "Status de Registro",
];

export const blacklist = [
    "Tipo de documento",
    "Número de documento",
    "Número de documento Ext.",
    "Posee Imagen",
    "Sexo",
];

export const conexion = [
    "Cliente",
    "Servicio",
    "Relación",
    "Rol",
    "Estado",
];
