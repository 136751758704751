import React from "react";

import Conexion from "pages/conexiones/Conexion";

export default function ConnectionModal({ usuarioId }) {
    return (
        <div style={{ width: '100%' }}>
            <Conexion usuarioId={usuarioId} />
        </div>
    );
}
